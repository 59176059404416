import React, {useContext} from 'react';
import styles from "./ActionBoard.module.scss";
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import PriceMenu from "./components/PriceMenu/PriceMenu";
import MultiplierButton from "./components/MultiplierButton/MultiplierButton";
import GoldNumberButton from "./components/GoldNumberButton/GoldNumberButton";
import Button from "../elements/Button/Button";
import {AppContext} from "../../contexts/AppContext";
import {GameContext} from "../../contexts/GameContext";
import {GAME, MULTIPLIER} from "../../config/constants";
import Utils from "../../utils/Utils";
import {useTranslation} from "react-i18next";

const ActionBoard = () => {
    const {t} = useTranslation();
    const {userPaymentSuccess, setUserPaymentSuccess} = useContext(AppContext);
    const {
        buyTicket,
        gamePrice,
        activePrice,
        setActivePrice,
        picked,
        setDisabled,
        setGameState,
        isMultiplierUsed,
        isGoldNumberUsed,
        goldNumber
    } = useContext(GameContext);

    const calculateResult = async () => {
        setDisabled(true);
        const result = await buyTicket();
        if (result !== null) {
            setUserPaymentSuccess(true);
            await Utils.timeout(3000);
            setUserPaymentSuccess(false);
            if (isMultiplierUsed) {
                setGameState(MULTIPLIER);
            } else {
                setGameState(GAME);
            }
        }
    };

    const checkDisabled = () => {
        return picked.length === 0 || (isGoldNumberUsed && goldNumber === 0);
    }

    return (
        <div className={styles.settings}>
            {userPaymentSuccess && <PaymentSuccess/>}
            <div className={styles.breakBox}>
                <div className={styles.divider}/>
                <div className={styles.textBlock}>
                    {t('actionBoard.info')}
                </div>
                <div className={styles.divider}/>
            </div>
            <div className={styles.actionBoard}>
                <PriceMenu activePrice={activePrice} setActivePrice={setActivePrice}/>
                <MultiplierButton/>
                <GoldNumberButton/>
            </div>
            <div className={styles.bottomBlock}>
                <Button className={styles.payButton} isDisabled={checkDisabled()} onClick={() => calculateResult()}>
                    {t('actionBoard.payButtonText', {amount: Utils.formatCurrency(gamePrice)})}
                </Button>
            </div>
        </div>
    );
};

export default ActionBoard;
