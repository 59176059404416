import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import apiClient from "./utils/apiClient";
import KenoBoard from "./components/Start/Start";
import i18n from "./i18n/i18n";
// import ErrorModal from "./components/ErrorModal/ErrorModal";
// import SoundManager from "./utils/SoundManager";
import styles from './App.module.scss'
import TopMenu from "./components/TopBar/TopMenu";
import {GameContext} from "./contexts/GameContext";
import {AppContext} from "./contexts/AppContext";
import SoundManager from "./utils/SoundManager";
import {AnimatePresence} from "framer-motion";
import {LOGIN} from "./config/constants";
import SystemPopUp from "./components/SystemPopUp/SystemPopUp";

interface AppProps {
    initData: {
        apiURL: string;
        tokenData:
            | {
            access_token: string;
            refresh_token: string;
        }
            | null;
        hide: string[];
        replayTicket: string | null;
        demomode: number | null;
        stakes: { gameId: number; cost: number }[];
        callback_event: (eventType: string, eventData: any) => void;
        locale: {
            language: string | undefined;
            currency: string | undefined;
            region: string | undefined;
        };
    };
}

function App({initData}: AppProps): JSX.Element {
    const {picked} = useContext(GameContext);
    const {
        callback,
        setCallback,
        setCurrencyLocale,
        appIsMuted,
        userData,
        setTokenData,
        currentModal,
        setCurrentModal,
        setIsReplay,
    } = useContext(AppContext);
    useEffect(() => {

        if (!initData) return;
        const {
            stakes,
            tokenData,
            hide,
            apiURL,
            demomode,
            replayTicket,
            callback_event,
            locale,
        } = initData;

        apiClient.setBaseURL(apiURL, tokenData);

        if (tokenData) {
            setTokenData(tokenData);
        } else {
            setCurrentModal(LOGIN);
        }

        if (locale.language) {
            i18n.changeLanguage(locale.language);
        }

        if (locale.region) {
            setCurrencyLocale(locale.region);
        }

        if (replayTicket) {
            setIsReplay(true);
            // getReplayTicket(replayTicket).then((response) => {
            //     setCurrentTicket(response);
            //     apiClient.get(`${response.tickets[0].url}`, {})
            //         .then((response: any) => {
            //
            //         })
            //         .catch((error) => {
            //             console.error('Unhandled error in apiClient.get:', error);
            //             setCurrentModal(ERROR);
            //         });
            // })
        }

        // if (hide) {
        //     setShowBalance(!hide.includes('balance'));
        //     setShowSoundButton(!hide.includes('sound'));
        //     setShowCloseButton(!hide.includes('close'));
        // }
        //

        if (typeof callback_event === 'function') {
            setCallback(() => callback_event);
        } else {
            console.warn('initData.callback_event is not a function');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initData])

    useEffect(() => {
        if (appIsMuted) {
            SoundManager.instance.muteApp(true);
        } else {
            SoundManager.instance.muteApp(false);
        }

        if (callback) {
            callback('audioToggle', !appIsMuted);
        } else {
            console.warn('callback is not defined');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appIsMuted, userData, callback]);

    return (
        <AnimatePresence mode='wait'>
            <div className={styles.App}>
                <TopMenu
                    displayBalance={true}
                    displaySoundButton={true}
                    displayCloseButton={true}
                    picked={picked}
                />

                <KenoBoard/>
                {currentModal && <SystemPopUp modalType={currentModal}/>}
                {/*{currentModal && <Modal modalType={currentModal}/>}*/}
            </div>
        </AnimatePresence>
    );
}

export default App;
