import React, {Fragment, useContext, useEffect, useState} from 'react';
import clsx from 'clsx';
import gsap from 'gsap';
import {motion} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import Wallet from './components/Wallet/Wallet';
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu";
import {GAME, INFO, MULTIPLIER, ROUND_END, START, STATS} from "../../config/constants";
import {AppContext} from "../../contexts/AppContext";
import {GameContext} from "../../contexts/GameContext";
// import SoundManager from '../../utils/SoundManager';
//assets
import Logo from '../../assets/logo/logo.png'
import SoundIcon from '../../assets/svg/sound.svg';
import pipeContainer from '../../assets/elements/pipe-container.png'
import styles from './TopMenu.module.scss';
import Countdown from "../Countdown/Countdown";
import SoundOffIcon from "../../assets/svg/sound-off.svg";
import cloud1 from '../../assets/background/cloud1.png'
import cloud2 from '../../assets/background/cloud2.png'
import cloud3 from '../../assets/background/cloud3.png'

const fadeVariant = {
    hidden: {opacity: 0},
    visible: {opacity: 1},
    exit: {opacity: 0},
};

interface Props {
    displayBalance: boolean;
    displaySoundButton: boolean;
    displayCloseButton: boolean;
    picked: number[];
}

function TopMenu({displayBalance, displaySoundButton, displayCloseButton, picked}: Props): JSX.Element {
    const {callback} = useContext(AppContext)
    const {t} = useTranslation();
    const {gameState, winningDraw, isResultsShown, showWinningDraw} = useContext(GameContext);

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [isOpen, setIsOpen] = useState(false);
    const [isMuted, setIsMuted] = useState(false);

    const isTablet = viewportWidth >= 768;
    const isDesktop = viewportWidth >= 1200;

    const closeGame: () => void = () => {
        callback('close', '');
    };

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1,
        }
    };

    const onClickSound: () => void = () => {
        setIsMuted(!isMuted);
        callback('audioToggle', !isMuted);
    };

    const checkGameState = () => {
        if (isDesktop) {
            return 'var(--topbar-height-desktop)';
        }

        switch (gameState) {
            case GAME:
                return isTablet ? 'var(--topbar-height-mobile-game)' : 'var(--topbar-height-mobile-game)';
            case ROUND_END:
                return isTablet ? 'var(--topbar-height-mobile-game)' : 'var(--topbar-height-mobile-game)';
            case MULTIPLIER:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-game)';
            case INFO:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-info)';
            case START:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-start)';
            default:
                return isTablet ? 'var(--topbar-height-tablet-start)' : 'var(--topbar-height-mobile-info)';
        }
    }

    const processWinningBalls = () => {
        return winningDraw.filter((num) => picked.includes(num));
    };

    const handleCountdownComplete = () => {
        showWinningDraw();
    }

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const clouds = gsap.utils.toArray(".cloud") as HTMLElement[];
        clouds.forEach((cloud, index) => {
            gsap.fromTo(
                cloud,
                {
                    x: window.innerWidth + cloud.offsetWidth + gsap.utils.random(0, 100),
                    y: gsap.utils.random(5, 80),
                    scale: gsap.utils.random(0.7, 1.2),
                },
                {
                    x: -window.innerWidth - cloud.offsetWidth,
                    duration: gsap.utils.random(40, 60),
                    ease: "none",
                    repeat: -1,
                    delay: index * 15,
                    repeatDelay: gsap.utils.random(5, 10),
                }
            );
        });
    }, []);

    // useEffect(() => {
    //   if () {
    //     SoundManager.instance.playVO('', {loop: true});
    //   } else {
    //       SoundManager.instance.playVO('', {loop: true});
    //   }
    // }, []);

    return (
        <motion.div
            className={clsx(styles.wrapper, {
                [styles[gameState]]: gameState,
            })}
            key="top-menu"
            animate={{
                height: checkGameState(),
                transition: {duration: gameState ? 0.4 : 0.2, ease: 'easeInOut'},
            }}
        >
            <img className={clsx(styles.cloud, 'cloud')} src={cloud1} alt={'cloud'}/>
            <img className={clsx(styles.cloud, 'cloud')} src={cloud2} alt={'cloud'}/>
            <img className={clsx(styles.cloud, 'cloud')} src={cloud3} alt={'cloud'}/>

            <div className={styles.topInnerWrapper}>
                {gameState === GAME || gameState === ROUND_END ? (
                    <motion.button
                        key="mute-button"
                        className={clsx(styles.menuButton, {[styles.hideButton]: !displaySoundButton})}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickSound}
                    >
                        <Fragment key={`${isMuted}`}>
                            {isMuted ? (
                                <img
                                    src={SoundIcon}
                                    className={clsx(styles.menuIcon, styles.isMuted)}
                                    alt=""
                                />
                            ) : (
                                <img
                                    src={SoundOffIcon}
                                    className={styles.menuIcon}
                                    alt=""
                                />
                            )}
                        </Fragment>
                    </motion.button>
                ) : (
                    <HamburgerMenu
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        displaySoundButton={displaySoundButton}
                        displayCloseButton={displayCloseButton}
                        isMuted={isMuted}
                        setIsMuted={setIsMuted}
                        closeGame={closeGame}
                    />
                )}
                <img
                    src={Logo}
                    className={styles.logo}
                    alt={'KENO'}
                />
                {gameState === GAME && (
                    <motion.img
                        id={'pipe'}
                        key={'pipe'}
                        src={pipeContainer}
                        alt="Asset"
                        initial={{y: '-100%', opacity: 0}}
                        animate={{y: 0, opacity: 1}}
                        exit={{y: '-100%', opacity: 0}}
                        transition={{duration: 2, ease: 'easeInOut'}}
                        className={styles.pipe}
                    />
                )}
                <Wallet displayBalance={displayBalance}/>
            </div>

            <div className={clsx(styles.startHeader, {
                [styles.isGame]: gameState === GAME || gameState === ROUND_END,
                [styles.isMultiplier]: gameState === MULTIPLIER,
                [styles.isStart]: gameState === START,
                [styles.isInfo]: gameState === INFO,
            })}>
                {gameState === INFO && (
                    <motion.h1
                        key="info"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {t('header.gameInfo')}
                    </motion.h1>
                )}
                {gameState === STATS && (
                    <motion.h1
                        key="info"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {t('header.prizePlans')}
                    </motion.h1>
                )}
                {gameState === START && (
                    <motion.h1
                        key="chooseNumbers"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {t('header.chooseNumbers')}
                    </motion.h1>
                )}
                {gameState === MULTIPLIER && (
                    <motion.div
                        key="multiplierHeaderBox"
                        className={styles.multiplierHeaderBox}
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        <h1>{t('header.multiplier')}</h1>
                        <p className={styles.multiplierText}>{t('header.multiplierSubHeading')}</p>
                    </motion.div>
                )}
                {gameState === GAME && (
                    <motion.div
                        key="countdown"
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        <Countdown duration={3} onComplete={handleCountdownComplete}/>
                    </motion.div>
                )}
                {(gameState === GAME || gameState === ROUND_END) && isResultsShown && (
                    <motion.div
                        key="gameHeaderBox"
                        className={styles.gameHeaderBox}
                        variants={fadeVariant}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{duration: 0.3}}
                    >
                        {processWinningBalls().length < 5 ? (
                            <>
                                <h1>{t('header.numbersRight', {rightNumbers: processWinningBalls().length})}</h1>
                                <p>{t('header.noPrize', {rightNumbers: processWinningBalls().length})}</p>
                            </>
                        ) : (
                            <>
                                <h1>{t('header.numbersRight', {rightNumbers: processWinningBalls().length})}</h1>
                                <p>{t('header.win', {prize: 'a prize'})}</p>
                            </>
                        )}
                    </motion.div>
                )}
            </div>

            {(gameState === START || (gameState === GAME && isDesktop)) &&
                <div className={clsx(styles.kenoCounter, {
                    [styles.kenoCounterActive]: picked.length > 0,
                    [styles.isTransparent]: gameState === GAME,
                    [styles.isStart]: gameState === START,
                })}>
                    <h3> {t('header.gameName')} {picked.length}</h3>
                </div>
            }
        </motion.div>
    );
}

export default TopMenu;
