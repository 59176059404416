import React, {useContext, useEffect, useState} from 'react';
import clsx from "clsx";
import {AnimatePresence, motion} from 'framer-motion';
import {GameContext} from "../../../../contexts/GameContext";
import Button from "../../../elements/Button/Button";
import styles from './MultiplierButton.module.scss';
import ButtonWheel from '../../../../assets/wheel/button-wheel.png';
import Tooltip from "../../../elements/Tooltip/Tooltip";
import Utils from "../../../../utils/Utils";
import {useTranslation} from "react-i18next";

const MultiplierButton = () => {
    const {t} = useTranslation();
    const {isMultiplierUsed, setIsMultiplierUsed, activePrice} = useContext(GameContext);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const mobileAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        variants: {
            start: {
                opacity: 1,
                scale: 1,
                x: 33,
                y: -4,
                rotate: 0,
                transition: {
                    duration: 0.7
                }
            },
            mid: {
                rotate: 360,
                transition: {
                    duration: 0.8,
                    delay: 1
                }
            },
        },
        animate: ["start", "mid"],
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const tabletAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        variants: {
            start: {
                opacity: 1,
                scale: 1,
                x: 46,
                y: -4,
                rotate: 0,
                transition: {
                    duration: 0.7
                }
            },
            mid: {
                rotate: 360,
                transition: {
                    duration: 0.8,
                    delay: 1
                }
            },
        },
        animate: ["start", "mid"],
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const desktopAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        variants: {
            start: {
                opacity: 1,
                scale: 1,
                x: 47,
                y: -7,
                rotate: 0,
                transition: {
                    duration: 0.7
                }
            },
            mid: {
                rotate: 360,
                transition: {
                    duration: 0.8,
                    delay: 1
                }
            },
        },
        animate: ["start", "mid"],
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const getAnimation = () => {
        if (windowWidth <= 767) {
            return mobileAnimation;
        } else if (windowWidth <= 1200) {
            return tabletAnimation;
        } else {
            return desktopAnimation;
        }
    };

    const handleClick = async () => {
        if (isAnimating) return;
        setIsMultiplierUsed(!isMultiplierUsed);
    };

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const handleAnimationComplete = () => {
        setIsAnimating(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.multiplierBox}>
            <Tooltip isVisible={isTooltipVisible}>
                <h3>{t('actionBoard.multiplier.label')}</h3>
                <p>{t('actionBoard.multiplier.subHeading')}</p>
            </Tooltip>
            <Button className={clsx(styles.multiplierButton, {[styles.inactive]: !isMultiplierUsed})}
                    onClick={handleClick}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
            >
                <label htmlFor="checkbox">
                    {isMultiplierUsed ? '✓' : ''}
                </label>
                <input
                    id="multiplier-checkbox"
                    type='checkbox'
                    className={styles.checkbox}
                    checked={isMultiplierUsed}
                />
                <div className={styles.text}>
                    <p className={styles.multiplierButtonText}>Multiplier</p>
                    <span>{'+ ' + Utils.formatCurrency(activePrice)}</span>
                </div>
            </Button>
            <AnimatePresence>
                {isMultiplierUsed && (
                    <motion.img
                        key="wheel"
                        alt="wheel"
                        className={styles.wheel}
                        src={ButtonWheel}
                        initial={getAnimation().initial}
                        variants={{
                            start: getAnimation().variants.start,
                            mid: getAnimation().variants.mid
                        }}
                        animate={getAnimation().animate}
                        exit={getAnimation().exit}
                        transition={{duration: 0.5}}
                        onAnimationStart={() => setIsAnimating(true)}
                        onAnimationComplete={handleAnimationComplete}
                    >
                    </motion.img>
                )}
            </AnimatePresence>
        </div>
    );
};

export default MultiplierButton;
