/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import qs from 'qs';

let baseURL = '';
let token: { access_token: string; refresh_token: string; } | null = null;
let showModalCallback: () => void;

const client = axios.create({
  baseURL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Axios request interceptor that adds token to headers.
 */
client.interceptors.request.use(
    (config) => {
        if (token?.access_token) {
            config.headers.Authorization = `Bearer ${token.access_token}`;
            config.headers['Content-Type'] = 'application/json';
            config.headers.accept = 'application/json';
        }
        return config;
    },
    (error) => {
      Promise.reject(error);
    }
);

/**
 * Axios response interceptor.
 * Checks if we get an 401 (Unauthorized) error.
 * Then requests a new token using the refresh_token.
 */
// response interceptor to refresh token on receiving token expired error
client.interceptors.response.use(
    (response) => response.data || response,

    (error) => {
      const originalRequest = error.config;
      const refreshToken = token?.refresh_token;
      if (
          refreshToken &&
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const formData = qs.stringify({
          client_secret: 'secret_for_randomstate_customer',
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
          client_id: 'randomstate_customer',
        });
        return axios
            .post(`${baseURL}connect/token`, formData, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            })
            .then((res) => {
              if (res.status === 200) {
                token = res.data;

                return client.request(originalRequest);
              }
            });
      }

      if (
          error.response &&
          error.response.status === 400 &&
          error.response.data === '"Token_Not_Found_Or_Expired"'
      ) {
        showModalCallback();
      }

      return Promise.reject(error.response || error);
    }
);

const setBaseURL = (url: string, tokenObj: { access_token: string; refresh_token: string; } | null) => {
  client.defaults.baseURL = url;
  baseURL = url;
  if (tokenObj) {
    token = tokenObj;
  }
};

const clientApi = {
  setBaseURL,
    get: (url: string, options: {}) => client.get(url, options),
    post: (url: string, payload: {}) => client.post(url, payload),
    put: (url: string, payload: {}) => client.put(url, payload),
    patch: (url: string, payload: {}) => client.patch(url, payload),
    delete: (url: string) => client.delete(url),
};

export const setModalCallback = (callback: () => void) => {
  showModalCallback = callback;
};

export default clientApi;
