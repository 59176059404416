import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import styles from './Prizes.module.scss'
import PrizesTable from "../PrizePlans/components/PrizesTable";
import goldNumber from "../../assets/prizes/gold-number.png";
import Button from "../elements/Button/Button";
import {INFO, START, STATS} from "../../config/constants";
import {GameContext} from "../../contexts/GameContext";

const Prizes = () => {
    const {t} = useTranslation();
    const {setGameState} = useContext(GameContext);

    return (
        <motion.div
            className={styles.prizesPage}
            id='info'
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
        >
            <div className={styles.prizesBox}>
                <PrizesTable
                    tableHeaders={[
                        {tableHeader: t('prizes.hits')},
                        {tableHeader: t('prizes.kenoPrize')},
                        {
                            tableHeader: t('prizes.specialPrize'),
                            imgSrc: goldNumber,
                        },
                    ]}
                />
            </div>
            <div className={styles.bottomBlock}>
                <Button
                    className={styles.statsButton}
                    isSecondary
                    onClick={() => setGameState(INFO)}>
                    {t('prizes.buttonOne')}
                </Button>
                <Button
                    className={styles.startButton}
                    onClick={() => setGameState(START)}>
                    {t('prizes.buttonTwo')}
                </Button>
            </div>
        </motion.div>
    );
}

export default Prizes;
