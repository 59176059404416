import {MutableRefObject, useContext, useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {gsap} from 'gsap';
import {AppContext} from "../../../../contexts/AppContext";
import {ReactComponent as Coin} from '../../../../assets/svg/coin.svg';
import Utils from "../../../../utils/Utils";
import styles from './Wallet.module.scss';

interface Props {
  displayBalance: boolean;
}

function Wallet({displayBalance}: Props): JSX.Element {
  const {userBalance} = useContext(AppContext);
  const [total, setTotal] = useState<string>('0');
  const previousBalance: MutableRefObject<any> = useRef();
  const balance: MutableRefObject<any> = useRef();

  const bounceBalance: () => void = () => {
    gsap.to(balance.current, {
      scale: 1.1,
      x: 5,
      ease: 'Sine.easeOut',
      duration: 0.4,
    });
    gsap.to(balance.current, {
      scale: 1,
      x: 0,
      delay: 0.1,
      ease: 'Sine.easeOut',
    });
  };

  const animateBalance: () => void = () => {
    gsap.to(previousBalance.current, {
      value: userBalance,
      duration: 0.5,
      ease: 'power2.out',
      onUpdate: () => {
        const totalString: string = Utils.formatCurrency(previousBalance.current.value);
        setTotal(totalString);

        bounceBalance();
      },
    });
  };

  useEffect(() => {
    if (userBalance !== null) {
      setTotal((oldVal: string) => {
        if (oldVal) {
          const oldValNumber: number = Number(oldVal.replace(/[^0-9.]+/g, ''));

          previousBalance.current = {
            value: oldValNumber,
          };
          if (userBalance !== null) {
            if (oldValNumber < userBalance) {
              animateBalance();
            }
          }
        }

        if (userBalance !== null) {
          return Utils.formatCurrency(userBalance);
        }
        return '';
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBalance]);

  return (
      <div>
          {userBalance !== null ? (
        <div className={clsx(styles.box, {[styles.hideBalance]: !displayBalance})}>
          <Coin className={styles.coinIcon}/>
          <span ref={balance} className={styles.balance}>
            {total}
          </span>
        </div>
        ): (
        <div className={clsx(styles.box, {[styles.hideBalance]: !displayBalance})}>
          <Coin className={styles.coinIcon}/>
          <span ref={balance} className={styles.balance}>
            0.00
          </span>
        </div>
        )}
      </div>
  );
}

export default Wallet;
