import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import clsx from "clsx";
import {motion} from 'framer-motion';
// @ts-ignore
import Chance from "chance";
import throttle from 'lodash/throttle';
import styles from "./Board.module.scss";
import Randomizer from "./components/Randomizer/Randomizer";
import {GameContext} from "../../contexts/GameContext";
import {GAME, ROUND_END, START} from "../../config/constants";
import Ball from "../Game/Ball/Ball";
import Cells from "./components/Cells/Cells";
import EndGameActionBoard from "../EndGameBoard/EndGameBoard";
import Utils from "../../utils/Utils";

const chance = new Chance();

interface BoardProps {
    handlePick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, val: number) => void;
}

const Board: React.FC<BoardProps> = ({handlePick}) => {
    const {
        setPicked,
        setIsGoldNumberUsed,
        setGoldNumber,
        winningDraw,
        gameState,
        setIsResultsShown,
        isResultsShown,
        setGameState,
    } = useContext(GameContext);
    const cellRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
    const gameBoardRef = useRef<HTMLDivElement | null>(null);
    const cellPositions = useRef<{ [key: number]: { targetX: number, targetY: number } }>({});
    const [bouncedBalls, setBouncedBalls] = useState(0);
    const bouncedBallNumbers = useRef<number[]>([]);
    const ballCount = winningDraw && winningDraw.length > 0 ? winningDraw.length : 20;

    const handleBallBounceComplete = (index: number) => {
        bouncedBallNumbers.current.push(index);
        setBouncedBalls(index + 1);
    };

    const randomize = (value: number) => {
        setIsGoldNumberUsed(false);
        setGoldNumber(0);
        const randomPick = chance.unique(chance.natural, value, {min: 1, max: 80});
        setPicked(randomPick);
    };

    const clearPicked = () => {
        setPicked([]);
        setIsGoldNumberUsed(false);
        setGoldNumber(0);
    };

    const resetBouncedBalls = () => {
        bouncedBallNumbers.current = [];
        setBouncedBalls(0);
    };

    const getCellPosition = useCallback((number: number) => {
        const cell = cellRefs.current[number];
        if (cell && gameBoardRef.current) {
            const rect = cell.getBoundingClientRect();
            const gameBoardRect = gameBoardRef.current.getBoundingClientRect();

            const viewportHeight = window.innerHeight;
            let marginOffset = 0;
            if (Utils.isDesktop()) {
                if (viewportHeight <= 1000) {
                    marginOffset = 0.42 * viewportHeight;
                } else {
                    marginOffset = 0.55 * viewportHeight;
                }
            } else if (Utils.isTablet()) {
                marginOffset = 0.5 * viewportHeight;
            }
            const topBarHeight = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--topbar-height-mobile-game')) || 0;

            const position = {
                targetX: rect.left - gameBoardRect.left + rect.width / 2,
                targetY: rect.top - gameBoardRect.top + rect.height / 2 + topBarHeight + marginOffset,
            };

            cellPositions.current[number] = position;
            return position;
        }
        return {targetX: 0, targetY: 0};
    }, []);

    useEffect(() => {
        const handleResize = throttle(() => {
            winningDraw.forEach((number) => {
                getCellPosition(number);
            });
        }, 200);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [winningDraw, getCellPosition]);

    useEffect(() => {
        if (bouncedBalls === ballCount) {
            setIsResultsShown(true);
        }
    }, [bouncedBalls, ballCount]);

    useEffect(() => {
        if (isResultsShown) {
            setTimeout(() => {
                resetBouncedBalls();
                setGameState(ROUND_END);
            }, 4000);
        }
    }, [isResultsShown]);

    return (
        <motion.div
            id={'board'}
            ref={gameBoardRef}
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.5}}
            className={clsx(styles.board, {
                [styles[gameState]]: gameState,
            })}>
            {gameState !== GAME && gameState !== ROUND_END && (
                <Randomizer randomize={randomize} clearPicked={clearPicked}/>
            )}
            {(gameState === GAME || gameState === START) && (
                <Cells cellRefs={cellRefs} handlePick={handlePick}/>
            )}
            {gameState === ROUND_END && (
                <EndGameActionBoard/>
            )}

            {winningDraw.map((number, index) => {
                const {targetX, targetY} = getCellPosition(number);

                return (
                    <Ball
                        key={number}
                        targetX={targetX}
                        targetY={targetY}
                        number={number}
                        delay={index * 1.4}
                        onBounceComplete={() => handleBallBounceComplete(index)}
                    />
                );
            })}
        </motion.div>
    );
};

export default Board;
