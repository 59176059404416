import React, {useContext} from 'react';
import styles from "./EndGameBoard.module.scss";
import Button from "../elements/Button/Button";
import {AppContext} from "../../contexts/AppContext";
import {GameContext} from "../../contexts/GameContext";
import {ERROR, GAME, MULTIPLIER, START} from "../../config/constants";
import PriceMenu from "../ActionBoard/components/PriceMenu/PriceMenu";
import MultiplierButton from "../ActionBoard/components/MultiplierButton/MultiplierButton";
import GoldNumberButton from "../ActionBoard/components/GoldNumberButton/GoldNumberButton";
import Utils from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import PaymentSuccess from "../ActionBoard/components/PaymentSuccess/PaymentSuccess";
import {useQuery} from "react-query";
import apiClient from "../../utils/apiClient";

const EndGameActionBoard = () => {
    const {t} = useTranslation();
    const {setUserPaymentSuccess, userPaymentSuccess, tokenData, setCurrentModal} = useContext(AppContext);
    const {
        buyTicket,
        currentTicket,
        setCurrentTicket,
        activePrice,
        setActivePrice,
        setGameState,
        setWinningDraw,
        setPicked,
        setIsMultiplierUsed,
        isMultiplierUsed,
        setGoldNumber,
        setDisabled,
        setMultiplier,
        multiplier,
        goldNumber,
        picked,
        setIsResultsShown,
        setIsGoldNumberUsed,
    } = useContext(GameContext);

    useQuery(
        'finalize',
        () =>
            apiClient.put(`${currentTicket.finalizeUrl}`, {
                status: 'Finalized',
            }),
        {
            enabled: !!currentTicket && !!tokenData?.access_token,
            refetchOnWindowFocus: false,
            onError: () => {
                setCurrentModal(ERROR);
            },
        }
    );

    const handlePlayAgain = async () => {
        setIsResultsShown(false);
        setCurrentTicket({});
        setWinningDraw([]);
        setMultiplier(multiplier);
        setIsMultiplierUsed(isMultiplierUsed);
        setGoldNumber(goldNumber);
        setPicked(picked);
        setDisabled(true);
        const result = await buyTicket();
        if (result !== null) {
            setUserPaymentSuccess(true);
            await Utils.timeout(3000);
            setUserPaymentSuccess(false);
            if (isMultiplierUsed) {
                setGameState(MULTIPLIER);
            } else {
                setGameState(GAME);
            }
        }
    };

    const handleBackToStart = () => {
        setIsResultsShown(false);
        setCurrentTicket({});
        setWinningDraw([]);
        setMultiplier(1);
        setIsMultiplierUsed(false);
        setIsGoldNumberUsed(false);
        setGoldNumber(0);
        setPicked([]);
        setGameState(START);
        setUserPaymentSuccess(false);
    };

    return (
        <div className={styles.settings}>
            {userPaymentSuccess && <div className={styles.paymentSuccessContainer}>
                {<PaymentSuccess/>}
            </div>}
            <div className={styles.actionBoard}>
                <PriceMenu activePrice={activePrice} setActivePrice={setActivePrice}/>
                <MultiplierButton/>
                <GoldNumberButton/>
            </div>
            <div className={styles.bottomBlock}>
                <Button className={styles.playButton} isDisabled={picked.length === 0} onClick={handlePlayAgain}>
                   <p className={styles.playButtonText}> {t('roundEnd.playAgain')} </p>
                </Button>
                <Button isSecondary className={styles.backToStartButton} onClick={handleBackToStart}>
                    {t('roundEnd.backToStart')}
                </Button>
            </div>
        </div>
    )
        ;
};

export default EndGameActionBoard;
