export const en = {
    translation: {
        soundButton: {
            soundOff: 'Sound off',
            soundOn: 'Sound on',
        },
        menu: {
            play: 'PLAY',
            tryDemo: 'TRY A DEMO ROUND',
            topScores: 'TOP SCORES',
            prizes: 'PRIZE PLAN',
            howItWorks: 'HOW TO PLAY!',
            achievements: 'ACHIEVEMENTS',
            statistics: 'STATISTICS',
        },
        actionBoard: {
            info: 'Choose your stake, Multiplier and Golden number',
            payButtonText: 'Pay {{amount}}',
            topPrize: 'Top prize',
            multiplier: {
                label: 'Double your stake to try multiplier.',
                subHeading: 'You have a chance of winning up to 10 times more!',
            },
            goldenNumber: {
                buttonName: 'Golden number',
                label: 'Choose one number as the Golden number to win bonus prize.',
            },
        },
        roundEnd: {
            playAgain: 'Play again!',
            backToStart: 'Back to start!',
        },
        randomizer: {
            randomizeNumbers: 'Randomize numbers',
            clearNumbers: 'Clear all numbers',
        },
        start: {
            heading: 'Welcome',
            subHeading: 'Time to test your sport quiz skills - and your luck to win cash!',
            playButton: 'PLAY',
            seeTopScoreButton: 'See top scores',
            seePrizesButton: 'See prizes',
        },
        header: {
            gameInfo: 'Game info',
            prizePlans: 'Prize plans',
            chooseNumbers: 'Choose your numbers!',
            multiplier: 'Multiplier',
            multiplierSubHeading: 'You have a chance of winning up to 10 times more!',
            goldenNumber: 'Golden number',
            gameName: 'Keno',
            numbersRight: 'You got {{rightNumbers}} Numbers right!',
            noPrize: 'Unfortunately, there’s no prize for only {{rightNumbers}} correct numbers.',
            win: 'Congratulations! You won {{prize}}!',
        },
        info: {
            header: 'How to play!',
            firstParagraph: 'Keno Game Info with something about multiplier and choose golden number.',
            secondParagraph: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit.',
            thirdParagraph: 'Aenean lacinia bibendum nulla sed consectetur.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. ',
            buttonOne: 'Prize plan',
            buttonTwo: 'Play',
        },
        prizes: {
            header: 'Prize plan',
            stake: '{{stake}} stake',
            prizeValue: 'Prize value:',
            hits: 'Hits',
            kenoPrize: 'Keno prize',
            specialPrize: 'Keno with golden number prize',
            buttonOne: 'Game info',
            buttonTwo: 'Play',
        },
        topscore: {
            today: 'Today',
            week: 'Week',
            leaderboard: 'Leaderboard',
            quizWinnersLabel: 'Previous winners',
            competitionEnd: 'competition ends:',
            seePrizes: 'See prizes',
            quizWinners: {
                tableHeaderDate: 'Date',
                tableHeaderWinner: 'Winner',
                tableHeaderScore: 'Score',
                weekNumber: 'Week {{weekNumber}}',
                noWinner: 'No winner',
            },
        },
        prizeplan: {
            title: 'Prize plan',
            text: 'The lottery consists of 1 000 000 lottery tickets per stake each with a total of 1 000 000 winning tickets.',
            heading1: 'Quantity',
            heading2: 'Prize',
            jackpot: 'Million chance',
            returnToPlayer: 'Return to player',
            payoutPercentage: '%',
            showGemValues: 'Show gem values',
            million: 'Million chance',
        },
        activeGame: {
            skipButton: 'skip',
        },
        system: {
            login: 'Sign in',
            logout: 'Logout',
            demo: 'Demo',
            loginTitle: 'Sign in',
            loginContent:
                'You need to sign in to be able to play and win.',
            walletTitle: "Not enough funds to play!",
            walletContent: 'Please deposit funds to your account.',
            sessionTimeoutTitle: 'Your session has timed out',
            sessionTimeoutContent: 'Please logon again to continue playing. If you were in the middle of playing a game, your game can be played once you have logged on again.',
            sessionTimeoutButton: 'Exit',
            unfinishedTitle: 'Resume your game?',
            unfinishedContent:
                'You have an unfinished ticket. Do you want to play it now?',
            generalErrorTitle: 'An error occurred',
            generalErrorContent: 'Something went wrong. Try again.',
            close: 'Close',
            resume: 'Play',
            viewResult: 'Check result',
            ok: 'Ok',
        },
        paymentSuccess: 'Payment successful!',
        amount: '{{amount}}',
        ok: 'Ok',
        currencyFormat: 'EUR',
        currencyLocale: 'en-GB',
        errors: {
            default: 'Something went wrong!',
            userBalance: 'Could not fetch balance, reload the page to try again.',
            purchase: 'A problem occurred during purchase, try again.',
            gameStop: 'You have game stop activated.',
            limitNotSet:
                'You have to set your game limits before purchasing tickets.',
            noAvailableTickets: 'There are no available tickets to purchase.',
            getTicketError: 'Something went wrong when collecting your ticket.',
            somethingWrongWithTicket: 'Unknown error with your ticket.',
            replayLoginError: 'You must login to replay your ticket.',
        },
    },
};
