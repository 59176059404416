import React, {useContext, useEffect, useState} from 'react';
import clsx from "clsx";
import {AnimatePresence, motion} from 'framer-motion';
import {GameContext} from "../../../../contexts/GameContext";
import Button from "../../../elements/Button/Button";
import styles from './GoldNumberButton.module.scss';
import Tooltip from "../../../elements/Tooltip/Tooltip";
import Utils from "../../../../utils/Utils";
import {useTranslation} from "react-i18next";

const GoldNumberButton = () => {
    const {t} = useTranslation();
    const {isGoldNumberUsed, setIsGoldNumberUsed, goldNumber, setGoldNumber, picked, setPicked, activePrice} = useContext(GameContext);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    const mobileAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        animate: {
            opacity: 1,
            scale: 1,
            x: 31,
            y: -6,
            rotate: 10,
            transition: {
                duration: 0.7
            }
        },
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const tabletAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        animate: {
            opacity: 1,
            scale: 1,
            x: 48,
            y: -4,
            rotate: 10,
            transition: {
                duration: 0.7
            }
        },
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const desktopAnimation = {
        initial: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0},
        animate: {
            opacity: 1,
            scale: 1,
            x: 50,
            y: -7,
            rotate: 10,
            transition: {
                duration: 0.7
            }
        },
        exit: {opacity: 0, scale: 2, y: -40, x: 20, rotate: 0}
    };

    const getAnimation = () => {
        if (windowWidth <= 767) {
            return mobileAnimation;
        } else if (windowWidth <= 1200) {
            return tabletAnimation;
        } else {
            return desktopAnimation;
        }
    };

    const handleMouseEnter = () => {
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const handleClick = async () => {
        if (!isAnimating) {
            const isUsed = !isGoldNumberUsed;
            setIsGoldNumberUsed(isUsed);
            if (!isUsed) {
                setGoldNumber(0);
                setPicked(picked.filter(number => number !== goldNumber));
            }
        }
    };

    const handleAnimationComplete = () => {
        setIsAnimating(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.goldNumberBox}>
            <Tooltip isVisible={isTooltipVisible}>
                <h3>{t('actionBoard.goldenNumber.label')}</h3>
            </Tooltip>
            <Button
                className={clsx(styles.goldenNumberButton, {[styles.inactive]: !isGoldNumberUsed})}
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <label htmlFor="checkbox">
                    {isGoldNumberUsed ? '✓' : ''}
                </label>
                <input
                    id="gold-number-checkbox"
                    type='checkbox'
                    className={styles.checkbox}
                    checked={isGoldNumberUsed}
                />
                <div className={styles.text}>
                    <p className={styles.multiplierButtonText}>{t('actionBoard.goldenNumber.buttonName')}</p>
                    <span>{'+ ' + Utils.formatCurrency(activePrice)}</span>
                </div>
            </Button>
            <AnimatePresence>
                {isGoldNumberUsed && (
                    <motion.div
                        key="number"
                        className={styles.goldNumber}
                        initial={getAnimation().initial}
                        animate={getAnimation().animate}
                        exit={getAnimation().exit}
                        transition={{duration: 0.5}}
                        onAnimationStart={() => setIsAnimating(true)}
                        onAnimationComplete={handleAnimationComplete}
                    >
                        <span> {goldNumber > 0 ? goldNumber : '?'}</span>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default GoldNumberButton;
