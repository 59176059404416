import React from 'react';
import Board from "../Board/Board";
import styles from './Game.module.scss';

const Game = () => {
    return (
        <div className={styles.gameBox}>
            <Board handlePick={() => {}}/>
        </div>
    );
};

export default Game;