import React, {useContext, useEffect, useRef} from 'react';
import gsap from 'gsap';
import styles from './Ball.module.scss'
import {GameContext} from "../../../contexts/GameContext";
import Utils from "../../../utils/Utils";

interface BallProps {
    targetX: number;
    targetY: number;
    delay: number;
    number: number;
    onBounceComplete: () => void;
    hasBounced?: boolean;
}

const Ball = ({targetX, targetY, number, delay, onBounceComplete, hasBounced}: BallProps) => {
    const ballRef = useRef<HTMLDivElement | null>(null);
    const {isResultsShown, picked, winningDraw} = useContext(GameContext);
    const isWinningBall = picked.includes(number) && winningDraw.includes(number);
    const ballSize = Utils.isTablet() || Utils.isDesktop() ? 46 : 32;

    const allBounceComplete = () => {
        const ball = ballRef.current;
        if (isResultsShown) {
            if (isWinningBall) {
                const winningBalls = document.querySelectorAll('.winningBall');
                const ballIndex = Array.from(winningBalls).length;
                const ballsPerRow = 4;
                const gridX = ballIndex % ballsPerRow;
                const gridY = Math.floor(ballIndex / ballsPerRow);

                const isTabletOrDesktop = Utils.isTablet() || Utils.isDesktop();
                const baseX = isTabletOrDesktop ? 9 : 14;
                const xSpacing = isTabletOrDesktop ? 5 : 14;
                const baseY = isTabletOrDesktop ? 15 : 12;
                const ySpacing = isTabletOrDesktop ? 8 : 7;

                gsap.to(ball, {
                    background: 'linear-gradient(180deg, #BDF774 0%, #1C854D 100%)',
                    y: `${baseY + gridY * ySpacing}vh`,
                    x: `${baseX + gridX * xSpacing}vw`,
                    scale: 1.4,
                    duration: 1,
                    ease: 'linear',
                    zIndex: 200,
                });

                if (ball) {
                    ball.classList.add('winningBall');
                }
            } else {
                gsap.to(ball, {
                    display: 'none',
                    opacity: 0,
                    duration: 0.5,
                    ease: 'linear',
                });
            }
        }
    };

    useEffect(() => {
        const ball = ballRef.current;
        const board = document.getElementById('board');
        if (!ball || !board) return;
        const boardRect = board?.getBoundingClientRect();
        let boardCenterX;
        if (Utils.isTablet() || Utils.isDesktop()) {
            boardCenterX = boardRect ? boardRect.width / 2 - 20 : 0;
        } else {
            boardCenterX = boardRect ? boardRect.width / 2 - 15 : 0;
        }

        const adjustedTargetX = targetX - (ballSize / 2);
        const adjustedTargetY = targetY - (ballSize / 2);

        gsap.timeline()
            .fromTo(ball,
                { y: '-100px', x: boardCenterX, opacity: 1, width: `${ballSize}px`, height: `${ballSize}px`, scale: 1.3 },
                { y: window.innerHeight - ballSize - 15, opacity: 1, duration: 2.8, ease: 'power2.in', yoyo: true, delay }
            )
            .to(ball, {
                y: adjustedTargetY,
                x: adjustedTargetX,
                duration: 1.1,
                opacity: 0.8,
                scale: 1,
                ease: 'linear',
                onComplete: () => onBounceComplete(),
            });
    }, []);

    useEffect(() => {
        if (isResultsShown) {
            allBounceComplete();
        }
    }, [isResultsShown]);

    useEffect(() => {
        const handleResize = () => {
            const ball = ballRef.current;
            if (!hasBounced || !ball) return;

            const newBallSize = Utils.isTablet() || Utils.isDesktop() ? 46 : 32;
            const adjustedTargetX = targetX - (newBallSize / 2);
            const adjustedTargetY = targetY - (newBallSize / 2);
            gsap.to(ball, {
                x: adjustedTargetX + 'px',
                y: adjustedTargetY + 'px',
                width: newBallSize + 'px',
                height: newBallSize + 'px',
                duration: 0.5,
            });
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [targetX, targetY, hasBounced]);

    return (
        <div
            ref={ballRef}
            className={styles.ball}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
                position: 'absolute',
                top: 0,
                left: 0,
                color: 'black',
                fontFamily: 'Kanit',
                fontSize: '16px',
                fontWeight: 'bold',
            }}
        >
            {isResultsShown && isWinningBall && number}
            <div className={styles.glare}></div>
            <div className={styles.secondary}></div>
            <div className={styles.inner}></div>
        </div>

    );
};

export default Ball;
