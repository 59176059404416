import React, {useContext} from 'react';
import {GameContext} from "../../../../contexts/GameContext";
import styles from "../../Board.module.scss";
import clsx from "clsx";
import {START} from "../../../../config/constants";

interface CellsProps {
    cellRefs: React.MutableRefObject<{ [key: number]: HTMLDivElement | null }>;
    handlePick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, val: number) => void;
}

const Cells = ({ cellRefs, handlePick }: CellsProps) => {
    const { board, picked, goldNumber, winningDraw, pickLimit, gameState } = useContext(GameContext);
    return (
        <>
        {board.map(row => (
                <div className={styles.row} key={row.toString()}>
                    {row.map(col =>
                        <div
                            className={clsx(styles.cell, {
                                [styles.picked]: picked.includes(col),
                                [styles.disabled]: picked.length === pickLimit && picked.indexOf(col) === -1 && gameState === START,
                                [styles.gold]: goldNumber === col,
                                [styles.winning]: winningDraw.includes(col),
                            })}
                            ref={(el) => {
                                cellRefs.current[col] = el;
                            }}
                            key={col}
                            onClick={e => handlePick(e, col)}
                        >
                            {col}
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default Cells;
