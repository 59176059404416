import React, {useContext, useEffect, useRef, useState} from 'react';
import throttle from 'lodash.throttle';
import {GameContext} from "../../contexts/GameContext";
import WheelPixiApp from './PixiApp/WheelPixiApp';
import Button from '../elements/Button/Button';
import clsx from "clsx";
import gsap from "gsap";
import {GAME} from "../../config/constants";
import styles from './Multiplier.module.scss';

const MultiplierWheel = () => {
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const applicationRef = useRef<WheelPixiApp | null>(null);
    const {setGameState} = useContext(GameContext);
    const [multiplier, setMultiplier] = useState<number | null>(null);

    const onResize = throttle(() => {
        if (applicationRef.current) {
            applicationRef.current.onResize();
        }
    }, 500);

    const handleSpinEnd = (multiplier: number) => {
        setMultiplier(multiplier);
    };

    useEffect(() => {
        if (applicationRef.current) {
            applicationRef.current.onResize();
        }
    }, []);

    useEffect(() => {
        if (!applicationRef.current && canvasRef.current) {
            applicationRef.current = new WheelPixiApp({
                htmlElement: canvasRef.current,
                onSpinEnd: handleSpinEnd,
            });
        }

        gsap.fromTo(
            canvasRef.current,
            { opacity: 0, y: -50 },
            { opacity: 1, y: 0, duration: 1, ease: 'bounce.out' }
        );
    }, [handleSpinEnd]);

    useEffect(() => {
        window.addEventListener('resize', onResize);

        return () => {
            if (applicationRef.current) {
                window.removeEventListener('resize', onResize);
                applicationRef.current.destroy();
                applicationRef.current = null;
            }
        };
    }, []);

    const handleSpin = () => {
        if (applicationRef.current) {
            applicationRef.current.spin();
        }
    };

    const handleSkip = () => {
        // setMultiplier(1);
        setGameState(GAME);
    };

    return (
        <div className={styles.container}>
            <div className={styles.canvasBox} ref={canvasRef}/>
            <div className={clsx(styles.resultBox)}>
                <div className={clsx(styles.textBox, {
                    [styles.fade]: multiplier !== null,
                    [styles.visible]: multiplier !== null,
                    [styles.hidden]: multiplier === null
                })}>
                    <h1 className={styles.result}>Multiplier: {` x ${multiplier}`}</h1>
                    <span className={styles.resultText}>You have a chance of winning {multiplier} times more!</span>
                </div>
                <Button onClick={handleSpin}
                        className={clsx(styles.spinButton, {
                            [styles.fade]: multiplier !== null,
                            [styles.visible]: multiplier === null,
                            [styles.hidden]: multiplier !== null,
                        })}>
                    Spin wheel
                </Button>
            </div>
            <Button isSecondary onClick={handleSkip} className={styles.skipButton}>
                Skip!
            </Button>
        </div>
    );
};

export default MultiplierWheel;
