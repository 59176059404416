import React, {useState} from "react";
import '../../i18n/i18n'
import {QueryClient, QueryClientProvider} from "react-query";
import {AppProvider} from "../../contexts/AppContext";
import {GameProvider} from "../../contexts/GameContext";

export default function Providers({children}: { children: React.ReactNode }) {
    const [queryClient] = useState(() => new QueryClient());
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <AppProvider>
                    <GameProvider>
                            {children}
                    </GameProvider>
                </AppProvider>
            </QueryClientProvider>
        </>
    )
}