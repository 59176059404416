import React, {Fragment, useContext} from 'react';
import clsx from "clsx";
import {motion} from 'framer-motion';
import styles from './HamburgerMenu.module.scss';
import MinimizeMenuIcon from '../../../../assets/svg/menu-minimize.svg';
import SoundIcon from '../../../../assets/svg/sound.svg';
import SoundOffIcon from '../../../../assets/svg/sound-off.svg';
import CloseIcon from '../../../../assets/svg/close.svg';
import InfoIcon from '../../../../assets/svg/info.svg';
import StatsIcon from '../../../../assets/svg/stats.svg';
import HomeIcon from '../../../../assets/svg/home.svg';
import MenuIcon from "../../../../assets/svg/menu.svg";
import {AppContext} from "../../../../contexts/AppContext";
import {GameContext} from "../../../../contexts/GameContext";
import {INFO, START, STATS} from "../../../../config/constants";

interface HamburgerMenuProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    displaySoundButton: boolean;
    displayCloseButton: boolean;
    isMuted: boolean;
    setIsMuted: (isMuted: boolean) => void;
    closeGame: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
                                                         isOpen,
                                                         setIsOpen,
                                                         displaySoundButton,
                                                         displayCloseButton,
                                                         isMuted,
                                                         setIsMuted,
                                                         closeGame
                                                     }) => {
    const {callback} = useContext(AppContext);
    const {setGameState} = useContext(GameContext);
    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1,
        }
    };

    const closeMenu: () => void = () => {
        setIsOpen(false);
    };

    const onClickSound: () => void = () => {
        setIsMuted(!isMuted);
        callback('audioToggle', !isMuted);
    };

    const onClickInfo: () => void = () => {
        setGameState(INFO);
        closeMenu();
    }

    const onClickStats: () => void = () => {
        setGameState(STATS);
        closeMenu();
    }

    const onClickHome: () => void = () => {
        setGameState(START);
        closeMenu();
    }

    return (
        <motion.div
            key='menu'
            className={styles.menu}
            animate={{
                height: isOpen ? '' : '40px',
                background: isOpen ? 'linear-gradient(180deg, #563642 0%, #AE374C 70%, #DAA691 100%)' : 'rgba(0,0,0,0)',
                transition: {
                    delayChildren: 0.3,
                    staggerChildren: 0.2
                }
            }}
        >
            {isOpen ? (
                <motion.button
                    className={clsx(styles.menuButton)}
                    key="minimize-button"
                    onClick={(): void => setIsOpen(!isOpen)}
                >
                    <img
                        src={MinimizeMenuIcon}
                        className={clsx(styles.menuIcon)}
                        alt=""
                    />
                </motion.button>
            ) : (
                <motion.button
                    className={clsx(styles.menuButton, styles.mainButton)}
                    onClick={(): void => setIsOpen(!isOpen)}
                >
                    <img
                        src={MenuIcon}
                        className={clsx(styles.menuIcon)}
                        alt=""
                    />
                </motion.button>
            )
            }

            {isOpen && (
                <>
                    <motion.button
                        key="home-button"
                        className={clsx(styles.menuButton)}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickHome}
                    >
                        <img src={HomeIcon} className={clsx(styles.menuIcon)} alt=""/>
                    </motion.button>
                    <motion.button
                        key="mute-button"
                        className={clsx(styles.menuButton, {[styles.hideButton]: !displaySoundButton})}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickSound}
                    >
                        <Fragment key={`${isMuted}`}>
                            {isMuted ? (
                                <img
                                    src={SoundIcon}
                                    className={clsx(styles.menuIcon, styles.isMuted)}
                                    alt=""
                                />
                            ) : (
                                <img
                                    src={SoundOffIcon}
                                    className={styles.menuIcon}
                                    alt=""
                                />
                            )}
                        </Fragment>
                    </motion.button>
                    <motion.button
                        key="info-button"
                        className={clsx(styles.menuButton)}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickInfo}
                    >
                        <img src={InfoIcon} className={styles.menuIcon} alt=""/>
                    </motion.button>
                    <motion.button
                        key="info-button"
                        className={clsx(styles.menuButton)}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={onClickStats}
                    >
                        <img src={StatsIcon} className={styles.menuIcon} alt=""/>
                    </motion.button>
                    <motion.button
                        key="close-game-button"
                        className={clsx(styles.menuButton, {[styles.hideButton]: !displayCloseButton})}
                        variants={item}
                        initial="hidden"
                        animate="visible"
                        onClick={closeGame}
                    >
                        <img src={CloseIcon} className={styles.menuIcon} alt=""/>
                    </motion.button>
                </>
            )}
        </motion.div>
    );
};

export default HamburgerMenu;
