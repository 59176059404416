import * as PIXI from 'pixi.js';
import {Application, Container, DisplayObject, Sprite} from 'pixi.js';
import { GlowFilter } from '@pixi/filter-glow';
import gsap from 'gsap';
// @ts-ignore
import Chance from 'chance';
import {PixiPlugin} from 'gsap/PixiPlugin';
import wheelBackground from '../../../assets/wheel/wheel-bg.png';
import wheelMultipliers from '../../../assets/wheel/sectors/wheel-multipliers.png';
import wheelArrow from '../../../assets/wheel/wheel-arrow.png';
import multiplier1 from '../../../assets/wheel/sectors/multiplier-1.png';
import multiplier3 from '../../../assets/wheel/sectors/multiplier-3.png';
import multiplier5 from '../../../assets/wheel/sectors/multiplier-5.png';
import multiplier10 from '../../../assets/wheel/sectors/multiplier-10.png';
import Utils from "../../../utils/Utils";

const chance = new Chance();

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);

interface StopAngle {
    [key: string]: number[];
}

const stopAngle: StopAngle = {
    '1x': [18, 126, 198, 306],
    '3x': [54, 162, 270],
    '5x': [90, 234],
    '10x': [342]
};

export interface PixiSetupOptions {
    htmlElement: HTMLDivElement;
    onSpinEnd: (chosenMultiplier: number) => void;
}

export default class WheelPixiApp {
    private app!: Application;
    private width!: number;
    private height!: number;
    private readonly htmlElement!: HTMLDivElement;
    private wheel!: Container;
    private background!: Sprite;
    private arrow!: Sprite;
    private multipliersWheel!: Sprite;
    private multipliers: Sprite[] = [];
    private textPositions: any[] = [];
    private pinPositions: any[] = [];
    private pinTriggers: number[] = [0, 36, 72, 108, 144, 180, 216, 252, 288, 324];
    private spinGsap!: gsap.core.Tween;
    private nailTimeline: gsap.core.Timeline | null = null;
    public onSpinEnd: (chosenMultiplier: number) => void;

    constructor({htmlElement, onSpinEnd}: PixiSetupOptions) {
        this.width = htmlElement.clientWidth;
        this.height = htmlElement.clientHeight;
        this.htmlElement = htmlElement;
        this.onSpinEnd = onSpinEnd;

        this.init();
        this.subscribe();
    }

    subscribe() {
        window.addEventListener('resize', this.onResize.bind(this));
    }

    init() {
        this.app = new Application({
            width: this.width,
            height: this.height,
            antialias: true,
            autoDensity: true,
            resolution: window.devicePixelRatio,
            backgroundAlpha: 0,
            resizeTo: this.htmlElement,
        });
        this.htmlElement.appendChild(this.app.view as HTMLCanvasElement);
        this.createChildren();
        this.onResize();

        const exposeApp = true;
        if (exposeApp) {
            (globalThis as any).__PIXI_APP__ = this.app; // eslint-disable-line
        }
    }

    createChildren() {
        this.wheel = new Container();

        this.background = this.createSprite(wheelBackground, {
            anchor: 0.5,
        });

        this.arrow = this.createSprite(wheelArrow, {
            position: {x: 1, y: -150},
        });
        this.arrow.anchor.set(0.5, 0);
        this.arrow.pivot.set(0.5, 0);

        this.multipliersWheel = this.createSprite(wheelMultipliers, {
            anchor: 0.5,
        });

        const multipliers = [
            multiplier1,
            multiplier3,
            multiplier5,
            multiplier10,
        ];

        const sectors = new Container();
        sectors.name = 'sectors';

        this.wheel.pivot.set(0.5, 0.5);
        this.wheel.addChild(
            this.background as DisplayObject,
            this.multipliersWheel as DisplayObject,
            this.arrow as DisplayObject,
        );

        multipliers.forEach((multiplier, index) => {
            const sprite = this.createSprite(multiplier, {
                anchor: 0.5,
            });
            sprite.visible = false;
            sprite.scale.set(1.2);
            sprite.position.set(0, -65);
            this.multipliers.push(sprite);
            this.wheel.addChild(sprite as DisplayObject);
        });

        this.app.stage.addChild(this.wheel as DisplayObject);
    }

    createSprite(texture: string, {
            anchor = 0,
            position = {x: 0, y: 0},
        }: {
            anchor?: number;
            position?: { x: number; y: number };
        } = {}
    ) {
        const sprite = Sprite.from(texture);
        sprite.anchor.set(anchor);
        sprite.position.set(position.x, position.y);

        return sprite;
    }

    calculatePinPosition(angle: number) {
        if (Utils.isMobile()) {
            return {
                x: (this.width / 2.2) * Math.cos(angle),
                y: (this.height / 2.2) * Math.sin(angle),
            };
        } else {
            return {
                x: (this.width / 3.8) * Math.cos(angle),
                y: (this.height / 3.8) * Math.sin(angle),
            };
        }
    }

    calculateTextPosition(angle: number) {
        if (Utils.isMobile()) {
            return {
                x: (this.width / 2.5) * Math.cos(angle),
                y: (this.height / 2.5) * Math.sin(angle),
            };
        } else {
            return {
                x: (this.width / 4.5) * Math.cos(angle),
                y: (this.height / 4.5) * Math.sin(angle),
            };
        }
    }

    spin() {
        const possibleMultipliers = [1, 3, 5, 10];
        const chosenMultiplier = chance.pickone(possibleMultipliers);

        const stopAngles = stopAngle[`${chosenMultiplier}x`];
        const targetAngle = chance.pickone(stopAngles) * (Math.PI / 180);

        const totalRotation = (Math.PI * 2) * 7 + targetAngle;
        let lastLoggedRotation = -1;

        this.spinGsap = gsap.to(this.multipliersWheel, {
            rotation: totalRotation,
            duration: 8,
            ease: "circ.out",
            onUpdate: () => {
                const totalRotationDegrees = totalRotation * (180 / Math.PI);
                const currentRotation = (this.multipliersWheel.rotation * (180 / Math.PI)) % 360;

                const nearestPin = this.pinTriggers.find(pin => Math.abs(pin - currentRotation) < 5);
                if (nearestPin !== undefined && lastLoggedRotation !== nearestPin) {
                    const spinProgressInDegrees = gsap.utils.mapRange(0, 1, 0, totalRotationDegrees, this.spinGsap.progress());
                    this.playPointerAnimation(spinProgressInDegrees);
                    lastLoggedRotation = nearestPin;
                }
            },
            onComplete: () => {
                this.onSpinEnd(chosenMultiplier);
                this.onMultiplierSelected(chosenMultiplier);
            }
        });
    }

    playPointerAnimation(spinProgress: number) {
        if (this.nailTimeline) {
            this.nailTimeline.kill();
        }

        const maxRotation = 0.5;
        const distanceToPin = Math.abs(0.5 - spinProgress);
        const snapIntensity = gsap.utils.clamp(0.5, 1, distanceToPin * 2);

        this.nailTimeline = gsap.timeline();

        this.nailTimeline.to(this.arrow, {
            rotation: -maxRotation * snapIntensity,
            duration: snapIntensity * 0.05,
            ease: 'elastic.out(1, 0.3)',
        });

        this.nailTimeline.to(this.arrow, {
            rotation: 0,
            duration: 0.2,
            ease: 'power2.out',
        });

        this.nailTimeline.play();
    }

    onMultiplierSelected(chosenMultiplier: number) {
        const indexMap = {
            1: 0,
            3: 1,
            5: 2,
            10: 3
        } as { [key: number]: number };

        const selectedIndex = indexMap[chosenMultiplier];
        const selectedSprite = this.multipliers[selectedIndex];

        selectedSprite.position.y += 20;

        gsap.timeline()
            .to([this.multipliersWheel, this.arrow], {
                alpha: 0,
                y: '-=100',
                duration: 0.7,
                ease: 'power1.inOut',
                onComplete: () => {
                    this.multipliersWheel.visible = false;
                    this.arrow.visible = false;
                }
            })
            .to(this.background, {
                alpha: 0,
                y: '+=100',
                skewX: 0.5,
                duration: 0.5,
                ease: 'power1.out'
            }, '-=0.5');

        selectedSprite.visible = true;

        const glowFilter = new GlowFilter({
            distance: 35,
            outerStrength: 2,
            innerStrength: 0,
            alpha: 0.7,
        });

        selectedSprite.filters = [glowFilter];

        gsap.timeline()
            .to(glowFilter, {
                distance: 75,
                outerStrength: 3,
                duration: 1.5,
                repeat: -1,
                yoyo: true,
                ease: 'power1.inOut'
            });

        const multiplierText = new PIXI.Text(`x${chosenMultiplier}`, {
            fontFamily: 'Kanit',
            fontSize: 32,
            fontWeight: 'bold',
            fill: 0xffffff,
            align: 'center'
        });
        multiplierText.anchor.set(0.5);
        multiplierText.position.set(0, -30);
        selectedSprite.addChild(multiplierText as DisplayObject);

        gsap.timeline()
            .to(selectedSprite.scale, {
                x: 1,
                y: 1,
                duration: 0.4,
                ease: 'power4.inOut'
            });
    }

    onResize() {
        if (this.wheel && this.wheel.transform && this.background && this.background.transform) {
            if (Utils.isSmallMobile()) {
                this.wheel.scale.set(0.9);
                this.wheel.position.set(160, 160);
                this.background.scale.set(0.85);
                this.multipliersWheel.position.set(1, -1);
                this.multipliersWheel.scale.set(0.62);
                this.arrow.scale.set(0.4);
                this.arrow.position.set(1, -150);
            } else if (Utils.isMobile()) {
                this.wheel.scale.set(1);
                this.wheel.position.set(160, 160);
                this.background.scale.set(0.85);
                this.multipliersWheel.position.set(1, -1);
                this.multipliersWheel.scale.set(0.62);
                this.arrow.scale.set(0.4);
                this.arrow.position.set(1, -150);
            } else if (Utils.isTablet()) {
                this.background.scale.set(1);
                this.multipliersWheel.scale.set(0.74);
                this.multipliersWheel.position.set(2, -2);
                this.arrow.scale.set(0.5);
                this.arrow.position.set(1, -175);
                this.wheel.position.set(260, 210);
            } else if (Utils.isDesktop()) {
                this.background.scale.set(1.15);
                this.multipliersWheel.scale.set(0.85);
                this.multipliersWheel.position.set(2, -2);
                this.arrow.scale.set(0.55);
                this.arrow.position.set(1, -202);
                this.wheel.position.set(260, 200);
            }
        } else {
            console.warn('Wheel or background is null or not properly initialized.');
        }

        if (this.textPositions && this.pinPositions) {
            this.textPositions.forEach(({text, angle}) => {
                const {x: textX, y: textY} = this.calculateTextPosition(angle);
                text.position.set(textX, textY);
            });

            this.pinPositions.forEach(({pin, angle}) => {
                const {x: pinX, y: pinY} = this.calculatePinPosition(angle);
                pin.position.set(pinX, pinY);
            });
        } else {
            console.warn('Text or pin positions not found.');
        }
    }

    destroy() {
        this.app.destroy(true, {children: true});
    }
}
