import React from "react";
import {ReactComponent as PaymentSuccessIcon} from "../../../../assets/svg/payment-successful.svg";
import styles from "./PaymentSuccess.module.scss";
import {useTranslation} from "react-i18next";

const PaymentSuccessful = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.successContainer}>
            <PaymentSuccessIcon/>
            <p>{t('paymentSuccess')}</p>
        </div>
    );
};

export default PaymentSuccessful;
