import React, {FC, useEffect, useRef, useState} from "react";
import styles from "./Tooltip.module.scss";

type TooltipProps = {
    isVisible: boolean;
    children: React.ReactNode;
};

const Tooltip: FC<TooltipProps> = ({ children, isVisible }) => {
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    useEffect(() => {
        if (window.matchMedia("(hover: none)").matches) {
            setIsTouchDevice(true);
        }
    }, []);

    if (!isVisible || isTouchDevice) {
        return null;
    }

    return (
        <div
            ref={tooltipRef}
            className={styles.tooltipContainer}>
            {children}
        </div>
    );
};

export default Tooltip;