//  Game states
export const MULTIPLIER = 'multiplier';
export const GAME = 'game';
export const START = 'start';
export const ROUND_END = 'endGame';
export const INFO = 'info';
export const STATS = 'stats';

//  Modals
export const LOGIN: string = 'LOGIN';
export const WALLET: string = 'WALLET';
export const SESSION_TIMEOUT: string = 'SESSION_TIMEOUT';
export const UNFINISHED: string = 'UNFINISHED';
export const ERROR: string = 'ERROR';
